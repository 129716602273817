import React from "react"
import Seo from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const PrivacyPage = () => {
  return (
    <>
      <Seo />
      <Layout>
        <Banner content="Privacy Policy" />
        <p>
          Currently, no information whatsoever is collected on any person
          browsing this website. Since I am a data guy, anonmyized data may be
          collected in the future but note that that doesn't really work
          retroactively :). 
        </p>
        <p>
          If any of this where to change in the future this page will surely
          reflect it. I'm also fully contactable using information in the contact
          section of this page.
        </p>
      </Layout>
    </>
  )
}

export default PrivacyPage
